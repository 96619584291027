import _defineProperty from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.splice.js";
import { AppConfigApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "langDetail",
  // 定义属性
  data: function data() {
    var _ref;
    return _ref = {
      dataSource: [],
      loading: false,
      infoData: {},
      content: {},
      contentList: [],
      visible: false,
      confirmLoading: false
    }, _defineProperty(_ref, "loading", false), _defineProperty(_ref, "title", "提示"), _defineProperty(_ref, "tipsDesc", ""), _defineProperty(_ref, "isDelete", false), _defineProperty(_ref, "isDeleteVisible", false), _ref;
  },
  mixins: [Mixin],
  computed: {
    json: function json() {
      var _this = this;
      this.content = {};
      this.contentList.forEach(function (item) {
        var data = {};
        data[item.key] = item.val;
        Object.assign(_this.content, data);
      });
      return JSON.stringify(this.content, null, "\t");
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.loading = true;
      this.content = {};
      this.contentList = [];
      AppConfigApi.getLangInfo({
        id: this.$route.query.id
      }).then(function (res) {
        _this2.loading = false;
        _this2.infoData = res[0];
        _this2.content = JSON.parse(res[0].content);
        for (var i in _this2.content) {
          var data = {
            key: i,
            val: _this2.content[i]
          };
          _this2.contentList.push(data);
        }
      });
    },
    create: function create() {
      console.log(this.contentList);
      if (this.contentList.length > 0 && (this.contentList[0].key == "" || this.contentList[0].val == "")) {
        this.toast("请先填写内容", "warning");
      } else {
        this.contentList.unshift({
          key: "",
          val: ""
        });
      }
    },
    delet: function delet(index) {
      this.contentList.splice(index, 1);
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      var data = {
        id: this.$route.query.id,
        content: this.content
      };
      AppConfigApi.updateInfo(data).then(function (res) {
        console.log(res);
        _this3.toast("操作成功", "success");
        _this3.isDeleteVisible = false;
        _this3.getDetail();
      });
    },
    submit: function submit() {
      if (this.contentList.length > 0 && (this.contentList[0].key == "" || this.contentList[0].val == "")) {
        this.toast("请先填写内容", "warning");
      } else {
        this.title = "\u786E\u8BA4";
        this.tipsDesc = "\u662F\u5426\u786E\u8BA4\u672C\u6B21\u4FEE\u6539\u8BB0\u5F55";
        this.isDeleteVisible = true;
      }
    },
    // 取消删除
    handleCancle: function handleCancle() {
      this.isDelete = false;
      this.isDeleteVisible = false;
    }
  },
  components: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.getDetail();
  }
};