var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("a-card", {
    staticClass: "container_a_card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "info"
  }, [_c("div", [_c("p", {
    staticClass: "title"
  }, [_vm._v("平台")]), _c("p", [_vm._v(_vm._s(_vm.infoData.platform == 1 ? "ios" : "android"))])]), _c("div", [_c("p", {
    staticClass: "title"
  }, [_vm._v("版本")]), _c("p", [_vm._v(_vm._s(_vm.infoData.version))])]), _c("div", [_c("p", {
    staticClass: "title"
  }, [_vm._v("语言")]), _c("p", [_vm._v(_vm._s(_vm.infoData.language))])])]), _c("div", [_c("div", {
    staticClass: "title"
  }, [_vm._v("配置内容")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "jsonConfig"
  }, _vm._l(_vm.contentList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "configTit"
    }, [_c("span", [_vm._v("key")]), _c("a-input", {
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.key,
        callback: function callback($$v) {
          _vm.$set(item, "key", $$v);
        },
        expression: "item.key"
      }
    })], 1), _c("div", {
      staticClass: "configTit"
    }, [_c("span", [_vm._v("val")]), _c("a-input", {
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.val,
        callback: function callback($$v) {
          _vm.$set(item, "val", $$v);
        },
        expression: "item.val"
      }
    })], 1), _c("a-button", {
      directives: [{
        name: "hasPermission",
        rawName: "v-hasPermission",
        value: ["commodity:btn:add"],
        expression: "['commodity:btn:add']"
      }],
      attrs: {
        type: "primary",
        size: "small"
      },
      on: {
        click: function click($event) {
          return _vm.delet(index);
        }
      }
    }, [_vm._v("删除")])], 1);
  }), 0), _c("div", {
    staticClass: "jsonHtml"
  }, [_vm._v(" " + _vm._s(_vm.json) + " ")])]), _c("div", {
    staticClass: "btnBox"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.create();
      }
    }
  }, [_vm._v("新增")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")])], 1)])]), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      title: _vm.title,
      loading: _vm.loading,
      isDelete: _vm.isDelete,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };